import { Injectable } from "@angular/core";
import { Location } from '@angular/common'; 
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class AppService {

  private errorMessageSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  private statusMessageSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  private exportCatalogSubject: BehaviorSubject<void> = new BehaviorSubject(null);
  private loadCatalogSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private loadMetadataSubject: BehaviorSubject<void> = new BehaviorSubject(null);
  private showCatalogSubject: BehaviorSubject<void> = new BehaviorSubject(null);
  private showDetailsSubject: BehaviorSubject<void> = new BehaviorSubject(null);

  public errorMessageObservable$: Observable<string> = this.errorMessageSubject.asObservable();
  public statusMessageObservable$: Observable<string> = this.statusMessageSubject.asObservable();
  public exportCatalogObservable$: Observable<void> = this.exportCatalogSubject.asObservable();
  public loadCatalogObservable$: Observable<boolean> = this.loadCatalogSubject.asObservable();
  public loadMetadataObservable$: Observable<void> = this.loadMetadataSubject.asObservable();
  public showCatalogObservable$: Observable<void> = this.showCatalogSubject.asObservable();
  public showDetailsObservable$: Observable<void> = this.showDetailsSubject.asObservable();

  public id: string;
  public type: string;
  public source: string;
  public showCatalog: boolean;
  public showDetails: boolean;
  public selectedSublayer: string | undefined = undefined;

  constructor(
    private location: Location,
    private router: Router,
  ) {
  }

  public setErrorMessage(message: string) {
    this.errorMessageSubject.next(message);
  }

  public setStatusMessage(message: string) {
    this.statusMessageSubject.next(message);
  }

  public exportCatalog() {
    this.exportCatalogSubject.next();
  }

  public loadCatalog(reload: boolean) {
    this.loadCatalogSubject.next(reload);
  }

  public loadMetadata() {
    this.loadMetadataSubject.next();
  }

  public toggleView(view: string) {
    this.showCatalog = false;
    this.showDetails = false;
    let url = this.router.createUrlTree(['/manager']).toString();

    if (view && view === 'catalog') {
      this.showCatalog = true;
      this.showCatalogSubject.next();
    } else if (view && view === 'details') {
      this.selectedSublayer = undefined;
      this.showDetails = true;
      this.showDetailsSubject.next();
      this.loadMetadata();
    }

    this.location.go(url);
  }

}
